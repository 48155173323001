<template>
	<div
		class="full-height pa-10-20"
	>
		<h6>{{ program.name }}</h6>

		<Search
			class="mt-10 box-ddd"
			:search="search"
			:option="search_option"

			@click="getSearch"
			@reset="reset"
			@setSearchDateType="setSearchDateType"
			@agency="setAgency"
		>
		</Search>

		<div class="mt-10 pa-10 box-ddd bg-white height-8 flex-column overflow-y-auto">
			<div class="justify-space-between">

				<button
					class="bg-identify pa-5-10"
					@click="toItem"
				>차감 등록</button>

				<div>
					<select
						class="pa-5-10 mr-10 size-px-12"
						v-model="search.size"
						@change="getSearch()"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>

			</div>
			<table
				v-if="items.length > 0"
				class="mt-10 table table-even"
			>
				<colgroup>
					<col width="80px" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
					<col width="180px" />

				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>차감일</th>
					<th>가맹점</th>
					<th>차감금액</th>
					<th>사유</th>

					<th>상태</th>
					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'list_' + index"
				>
					<td>{{ item.idx }}</td>
					<td>{{ item.date | transDate}}</td>
					<td>{{ item.shopName }}</td>
					<td class="text-right">{{ item.amount | makeComma }}원</td>
					<td>{{ item.reason }}</td>

					<td :class="'color-' + item.state_color">{{ item.state_name }}</td>

					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="toDetail(item)"
						>상세보기</button>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center mt-10 top-line-identify"
			>
				<div class="text-center">
					<v-icon
						class="size-px-48 "
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				class="mt-auto"
				@click="getSearch"
			></Pagination>

		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"

			@finish="is_excel = !is_excel"
		></Excel>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"
			:top="true"

			title="가맹점 차감 등록"
			width="400px"
			content_class="ptb-30"
			@close="close"
		>
			<MinusMerchantItem
				slot="modal-content"

				@close="close"
				@success="success"
				@setNotify="setNotify"

			></MinusMerchantItem>
		</Modal>

		<Modal
			:is_modal="is_modal2"
			:option="{}"
			:top="true"

			title="가맹점 차감 상세"
			width="400px"
			content_class="ptb-30"
			@close="close"
		>
			<MinusMerchantDetail
				slot="modal-content"
				:idx="item.idx"

				@close="close"
				@success="success"
				@setNotify="setNotify"

			></MinusMerchantDetail>
		</Modal>
	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Excel from "@/components/Excel";
import Modal from "@/components/Modal";

import MinusMerchantItem from "@/view/Minus/MinusMerchantItem";
import MinusMerchantDetail from "@/view/Minus/MinusMerchantDetail";

export default {
	name: 'MinusMerchantList',
	components: {MinusMerchantDetail, MinusMerchantItem,  Modal, Excel, Search, Pagination},
	data: function(){
		return {
            user: [],
			program: {
				name: '가맹점 차감 내역'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: this.$route.query.page ? this.$route.query.page : 1
				,size: this.$route.query.size ? this.$route.query.size : 10
				,search_type: this.$route.query.search_type ? this.$route.query.search_type : ''
				,keyword: this.$route.query.keyword ? this.$route.query.keyword : ''
				,state: this.$route.query.state ? this.$route.query.state : ''
				,sDate: this.$route.query.sDate ? this.$route.query.sDate : this.date.getLastDate(this.date.getToday('-'), 4, '-')
				,eDate: this.$route.query.eDate ? this.$route.query.eDate : this.date.getLastDate(this.date.getToday('-'), -3, '-')
				,search_date_type: 'weekly'
				,branchIdx: ''
				,distributorIdx: ''
				,agencyIdx: ''
				,resellerIdx: ''
				,total_count: 0
			}
			,search_option: {
				sDate: true
				,eDate: true
				,search_type: [
                    {name: '아이디', column: 1}
                    , {name: 'MID', column: 2}
                    , {name: '가맹점명', column: 3}
                    , {name: '대표자명', column: 4}
                    , {name: '사업자번호', column: 5}
				]
				,is_search_date_type: true
			}
			,is_excel: false
			,excel_data: {
				name: '가맹점 정산 내역'
				,header: [
					{ key: 0, name: 'NO', column: 'calIdx'}
					,{ key: 0, name: '매출 시작일', column: 'startDate'}
					,{ key: 0, name: '매출 종료일', column: 'endDate'}
					,{ key: 0, name: '정산 기준일', column: 'payment'}
					,{ key: 0, name: '정산주기', column: 'amount'}
					,{ key: 0, name: '가맹점', column: 'fee'}
					,{ key: 0, name: '결제건수', column: 'account'}
					,{ key: 0, name: '승인금액', column: 'name'}
					,{ key: 0, name: '수수료율', column: 'name2'}
					,{ key: 0, name: '수수료', column: 'rDate'}
					,{ key: 0, name: '정산금액', column: 'status_name'}
					,{ key: 0, name: '승인여부', column: 'status_name'}
					,{ key: 0, name: '지급여부', column: 'status_name'}

				]
				,content: null
			}
			,items: []
			,item: {

			}

			,item_payment: []
			,summary: {}
			,is_modal: false
			,modal_option: {}
			,is_modal3: false
			,modal_option3: {}
			,is_modal2: false
			,modal_option2: {}
			,is_all: false
			,item_confirm: {
				state: ''
				, approval: ''
				, idx: []
			}
		}
	}
	,computed: {
		item_list: function(){
			let self = this
			return this.items.filter(function(item){

				for(let i = 0; i < self.codes.minus_state.length; i++){
					if(item.state == self.codes.minus_state[i].code){
						item.state_name = self.codes.minus_state[i].name
						item.state_color = self.codes.minus_state[i].color
						break
					}
				}

				return item
			})
		}
		,select_items: function(){
			let items = []
			this.items.filter(function(item){
				if(item.is_select){
					items.push(item.infoIdx)
				}
			})

			return items
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$layout.onLoading()

				this.search.startDate = this.search.sDate.replaceAll('-', '.')
				this.search.endDate = this.search.eDate.replaceAll('-', '.')
                if(this.search.search_type) {
                    this.search.keywordType = this.search.search_type
                } else {
                    delete this.search.keywordType
                }
				const result = await this.$Axios({
					method: 'get'
					,url: 'calculate/shop/deduction'
					,data: this.search
				})

				if(result.success){
                    console.log(result)
					this.items = result.data.tableList.data
					this.search.total_count = result.data.tableList.totalCount
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,toDetail: function(item){
			this.item = item
			this.is_modal2 = true
		}
		,reset: function(){
			this.search = {
				page: 1
				,size: 10
				,search_type: ''
				,keyword: ''
				,state: ''
				,sDate: this.date.getLastDate(this.date.getToday('-'), 4, '-')
				,eDate: this.date.getLastDate(this.date.getToday('-'), -3, '-')
				,search_date_type: 'weekly'
				,branchIdx: ''
				,distributorIdx: ''
				,agencyIdx: ''
				,resellerIdx: ''
				,total_count: 0
			}
			
			this.getData()
		}
		,getSearch: function(page){

			if(page){
				this.search.page = page
			}

			this.getData()
		}

		,toExcel: function(){
			this.excel_data.content = this.items
			this.is_excel = true
		}
		,setSearchDateType: function(type){
			this.search.search_date_type = type
		}
		,close: function(){
			this.is_modal = false
			this.is_modal2 = false
			this.is_modal3 = false
		}
		,cancel: async function(){

		}
		, isConfirm: function(){
			if(this.select_items.length <= 0){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.not_select})
				return
			}
			if(this.item_confirm.state === ''){
				this.$layout.setNotify( { type: 'error', message: '처리상태를 선택하세요'})
			}else {

				let status_name = ''

				for(let i = 0; i < this.codes.settlement_payment_state.length; i++){
					if(this.item_confirm.state == this.codes.settlement_payment_state[i].code){
						status_name = this.codes.settlement_payment_state[i].name
					}
				}

				this.is_modal3 = true
				this.modal_option3.content = '선택한 항목을 ' + status_name + ' 처리 하시겠습니까?1'
			}
		}
		,isApproval: function(){
			if(this.select_items.length <= 0){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.not_select})
				return
			}
			if(this.item_confirm.approval === ''){
				this.$layout.setNotify( { type: 'error', message: '처리상태를 선택하세요'})
			}else {

				let status_name = ''

				for(let i = 0; i < this.codes.settlement_confirm_state.length; i++){
					if(this.item_confirm.approval == this.codes.settlement_confirm_state[i].code){
						status_name = this.codes.settlement_confirm_state[i].name
					}
				}

				this.is_modal = true
				this.modal_option.content = '선택한 항목을 ' + status_name + ' 처리 하시겠습니까?'
			}
		}
		,doConfirm: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'put'
					,url: 'calculate/shop/history'
					,data: {
						infoIdxList: this.select_items
						,state: this.item_confirm.state
						,reason: this.item_confirm.reason
					}
				})

				if(result.success){
					this.getSearch()
					this.$layout.setNotify( { type: 'success', message: this.$language.common.success})
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.close()
				this.$layout.offLoading()
			}
		}
		,setAgency: function(agency){
			this.search.branchIdx = agency.branchList
			this.search.distributorIdx = agency.distributorList
			this.search.agencyIdx = agency.agencyList
			this.search.resellerIdx = agency.resellerList
			this.getSearch(this.search.page)
		}
		,setNotify: function( { type, message }){
			this.$layout.setNotify( { type: type, message: message })
		}
		,success: function(){
			this.getSearch()
			this.close()
		}
		,doApproval: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'put'
					,url: 'calculate/shop/history/approval'
					,data: {
						infoIdxList: this.select_items
						,state: this.item_confirm.approval
					}
				})

				if(result.success){
					this.getSearch()
					this.$layout.setNotify( { type: 'success', message: this.$language.common.success})
				}else{
					this.$layout.setNotify( { type: 'error', message: this.$language.common.fail})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.close()
				this.$layout.offLoading()
			}
		}
		,toItem: function(){
			this.is_modal = !this.is_modal
		}
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
		this.getData()
	}
	,watch: {
		is_all:{
			handler: function(call){
				let self = this
				this.items.filter(function(item){
					self.$set(item, 'is_select', call)
				})
			}
		}
		,'search.search_date_type': {
			handler: function(call){
				let today = this.date.getToday('-')
				this.search.eDate = today
				switch(call){
					case 'today':
						this.search.sDate = today

						this.getData()
						break
					case 'weekly':
						this.search.sDate = this.date.getLastDate(today, 7, '-')

						this.getData()
						break
					case 'month':
						this.search.sDate = this.date.getLastDate(today, 30, '-')

						this.getData()
						break
					case '3':
						this.search.sDate = this.date.getLastDate(today, 90, '-')
						break
					case '6':
						this.search.sDate = this.date.getLastDate(today, 180, '-')
						break
				}
			}
		}
	}
}
</script>

<style>
.bg-icon-paper { background: url('../../assets/images/icon/icon-paper.svg') no-repeat 10px center; padding-left: 70px;}
.bg-icon-round-count { background: url('../../assets/images/icon/icon-round-count.svg') no-repeat 10px center; padding-left: 70px;}
.bg-icon-calc { background: url('../../assets/images/icon/icon-calc.svg') no-repeat 10px center; padding-left: 70px;}
</style>