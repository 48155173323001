<template>
	<div class="full-height pa-10 position-relative">
		<div
			v-if="!merchant.userIdx"
			class="full-height flex-column"
		>
			<div class="justify-space-between">
				<input
					v-model="search.keyword"
					class="input-box-inline vertical-middle flex-1 pa-10"
					placeholder="가맹점 조회"
				/>

				<v-icon
					small
					class="bg-identify color-eee pa-10 vertical-middle box cursor-pointer"
					@click="getSearch(1)"
				>mdi mdi-magnify</v-icon>
			</div>

			<ul class="mt-10 full-height overflow-y-auto pr-10">
				<li
					v-for="(merchant, index) in items"
					:key="'merchant_' + index"
					@click="setMerchant(merchant)"
					class="pa-10 mb-5 box radius-10 justify-space-between cursor-pointer"
				>
					<span class="flex-1 text-left">{{ merchant.shopName }}</span>
					<v-icon
						small
					>mdi mdi-chevron-right</v-icon>
				</li>
			</ul>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				class="mt-auto"

				@click="getSearch"
			></Pagination>
		</div>

		<div
			v-else
			class="full-height flex-column justify-center "
		>
			<div class=" full-width ">
				<div
					class="box text-left pa-5-10 cursor-pointer"
					@click="clear"
				>
					<v-icon
						class="vertical-middle"
					>mdi mdi-chevron-left</v-icon>
					<span class="vertical-middle">{{ merchant.shopName }}</span>
				</div>
				<div class="mt-10">
					<input
						v-model="formattedMinus"
						type="text"
						placeholder="차감 금액을 입력하세요"
						class="input-box pa-10"
						@input="formatInput"
					>
				</div>
				<div class="mt-10 ">
					<input
						v-model="item.date"
						placeholder="차감일을 입력하세요"
						class="input-box pa-10"
						readonly
						@click="datePickerStart = !datePickerStart"
					>
					<v-date-picker
						v-if="datePickerStart"
						v-model="item.date"
						no-title
						scrollable
						class="position-absolute"
						style="top: -10px; left: 35px;"
						@change="datePickerStart = !datePickerStart"
					></v-date-picker>
				</div>
				<div class="mt-10">
					<textarea
						v-model="item.reason"
						placeholder="차감사유를 입력하세요"
					></textarea>
				</div>
			</div>
			<div
				class="mt-auto justify-center pa-10"
			>
				<button
					class="pa-10-20 bg-identify mr-10"
					@click="save"
				>저장</button>
				<button
					class="pa-10-20 bg-bbb"
					@click="close"
				>취소</button>
			</div>
		</div>
	</div>
</template>

<script>

import { rules} from "@/resources/rules/rules";
import Pagination from "@/components/Pagination";

export default {
	name: 'MinusMerchantItem'
	,components: {Pagination}
	,props: []
	, data: function(){
		return {
			rules: rules
			,program: {
				name: '가맹점 차감 등록'
			}
			,search: {
                keyword: ''
				,page: 1
				,size: 10
			}
			,items: []
			,item: {

			}
			,item_merchant: {

			}
			,merchant: {

			}
			,datePickerStart: false
            ,formattedMinus: ''
		}
	}
	,methods: {
        formatInput: function() {
            let format = 0
            format = parseFloat(this.formattedMinus.replace(/,/g, "")) || 0;
            this.item.amount = format
            this.formattedMinus = format.toLocaleString("en-US");

        },
		getData: async function(){
			try{
				const result = await this.$Axios({
					method: 'get'
					,url: 'shop/simple'
					,data: this.search
				})

				if(result.success){
					this.items = result.data.data
                    this.search.total_count = result.data.totalCount
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
			}
		}
		,setMerchant: function(merchant){
			this.merchant = merchant
		}
		,clear: function(){
			this.merchant = {}
		}
		,save: async  function(){
            if(!this.item.amount){
                this.$layout.setNotify( { type: 'success', message: '차감금액을 입력해 주세요.' })
            }

			this.item.shopIdx = this.merchant.userIdx
			this.item.shopName = this.merchant.shopName
			this.item.procDate = this.item.date.replaceAll('-', '.')
			try{
				const result = await this.$Axios({
					method: 'post'
					,url: 'calculate/shop/deduction'
					,data: this.item
				})

				if(result.success){
					this.$layout.setNotify( { type: 'success', message: this.$language.common.success })
					this.$emit('success')
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				console.log(e)
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
			}
		}
		,getSearch: function(page){
			this.search.page = page
			this.getData()
		}
		,close: function(){
			this.$emit('close')
		}
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
		this.getData()
	}
}
</script>